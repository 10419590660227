<template>
	<section id="organizations">
		<!-- Organizations Table Card -->
		<b-card no-body>
			<!-- Table Top -->
			<b-row>
				<b-col cols="12" md="12">
					<div class="d-flex align-items-center justify-content-end mt-2 mr-2">
						<b-button
							variant="primary"
							@click="
								active = !active;
								data.selectedOrganization = {};
							"
						>
							<feather-icon icon="PlusIcon" class="mr-25" />
							<span class="text-nowrap">Add organization</span>
						</b-button>
					</div>
				</b-col>
			</b-row>
			<organizations-table
				@editOrganization="editOrganization"
				@deleteOrganization="deleteOrganization"
				:table-data="data.organizations"
				class="mt-2 mt-md-2"
			/>
		</b-card>
		<!--/ Organizations Table Card -->

		<!-- SIDEBAR -->
		<b-sidebar
			width="40%"
			right
			shadow
			parent="body"
			default-index="1"
			spacer
			v-model="active"
			title="Organization settings"
			class="sidebar"
		>
			<div class="px-3 py-2">
				<validation-observer ref="simpleRules">
					<b-form @submit.prevent>
						<b-row>
							<b-col md="12">
								<b-form-group
									label="Name"
									label-for="inputName"
									label-cols-md="12"
								>
									<validation-provider
										#default="{ errors }"
										name="Organization name"
										rules="required"
									>
										<b-form-input
											id="inputName"
											:state="errors.length > 0 ? false : null"
											placeholder="Organization name"
											v-model="data.selectedOrganization.name"
										/>
										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
								</b-form-group>
							</b-col>
							<b-col md="12">
								<b-form-group
									label="Shortname"
									label-for="inputShortname"
									label-cols-md="12"
								>
									<validation-provider
										#default="{ errors }"
										name="Organization shortname"
										rules="required"
									>
										<b-form-input
											id="inputShortname"
											:state="errors.length > 0 ? false : null"
											placeholder="Organization shortname"
											v-model="data.selectedOrganization.shortname"
										/>
										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
								</b-form-group>
							</b-col>
							<b-col md="12">
								<b-form-group
									label="HHIP Result URL"
									label-for="inputUrl"
									label-cols-md="12"
								>
									<validation-provider
										#default="{ errors }"
										name="Organization Result URL"
										rules="required"
									>
										<b-form-input
											id="inputUrl"
											placeholder="HHIP Result URL"
											rows="3"
											v-model="data.selectedOrganization.resulturl"
										/>
										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
								</b-form-group>
							</b-col>
							<b-col md="12">
								<b-form-group
									label="HHIP Password"
									label-for="inputPassword"
									label-cols-md="12"
								>
									<validation-provider
										#default="{ errors }"
										name="Organization password"
										rules="required"
									>
										<b-form-input
											id="inputPassword"
											placeholder="HHIP password"
											rows="3"
											v-model="data.selectedOrganization.password"
										/>
										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
								</b-form-group>
							</b-col>

							<!-- save -->
							<b-col md="12">
								<b-button
									v-ripple.400="'rgba(255, 255, 255, 0.15)'"
									type="submit"
									variant="primary"
									class="mr-1"
									@click.prevent="validationForm"
								>
									Save
								</b-button>
							</b-col>
						</b-row>
					</b-form>
				</validation-observer>
			</div>
		</b-sidebar>
		<!-- /SIDEBAR -->
	</section>
</template>



<script>
import {
	BCard,
	BCardText,
	BRow,
	BCol,
	BForm,
	BFormGroup,
	BFormInput,
	BFormTextarea,
	BButton,
	BSidebar,
} from "bootstrap-vue";
import vSelect from "vue-select";
import OrganizationsTable from "./OrganizationsTable.vue";

import Ripple from "vue-ripple-directive";
import Cleave from "vue-cleave-component";
import { ValidationProvider, ValidationObserver } from "vee-validate";

import { required } from "@validations";

export default {
	data() {
		return {
			active: false,
			data: {
				selectedOrganization: {},
				organizations: [],
			},
		};
	},
	components: {
		BRow,
		BCol,
		BCard,
		BCardText,
		BForm,
		BFormGroup,
		BFormInput,
		BFormTextarea,
		BButton,
		vSelect,
		Cleave,
		OrganizationsTable,
		BSidebar,
		ValidationProvider,
		ValidationObserver,
	},
	directives: {
		Ripple,
	},
	methods: {
		async validationForm() {
			this.$refs.simpleRules.validate().then((success) => {
				if (success) {
					this.saveOrganization();
				}
			});
		},
		async saveOrganization() {
			var url = "/organizations";
			var method = "post";

			if (this.data.selectedOrganization.id) {
				url = "/organizations/" + this.data.selectedOrganization.id;
				method = "put";
			}

			const res = await this.callApi(method, url, this.data.selectedOrganization);

			this.active = false;
			this.loadOrganizations();
		},
		editOrganization(organization) {
			this.data.selectedOrganization = { ...organization };
			this.active = true;
		},
		async deleteOrganization(organization) {
			if (!confirm("Are you sure you want to delete this organization?")) return;
			const res = await this.callApi("delete", "/organizations/" + organization.id);
			this.loadOrganizations();
		},
		async loadOrganizations() {
			const res = await this.callApi("get", "/organizations");
			this.data.organizations = res.data.result;
		},
		onContext(ctx) {
			// The date formatted in the locale, or the `label-no - date - selected` string
			this.formatted = ctx.selectedFormatted;
			// The following will be an empty string until a valid date is entered
			this.dobSelected = ctx.selectedYMD;
		},
	},
	mounted() {
		this.loadOrganizations();
	},
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.col-form-label {
	min-height: 2.45rem;
}
</style>