var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{attrs:{"id":"organizations"}},[_c('b-card',{attrs:{"no-body":""}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('div',{staticClass:"d-flex align-items-center justify-content-end mt-2 mr-2"},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){_vm.active = !_vm.active;
							_vm.data.selectedOrganization = {};}}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"PlusIcon"}}),_c('span',{staticClass:"text-nowrap"},[_vm._v("Add organization")])],1)],1)])],1),_c('organizations-table',{staticClass:"mt-2 mt-md-2",attrs:{"table-data":_vm.data.organizations},on:{"editOrganization":_vm.editOrganization,"deleteOrganization":_vm.deleteOrganization}})],1),_c('b-sidebar',{staticClass:"sidebar",attrs:{"width":"40%","right":"","shadow":"","parent":"body","default-index":"1","spacer":"","title":"Organization settings"},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[_c('div',{staticClass:"px-3 py-2"},[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Name","label-for":"inputName","label-cols-md":"12"}},[_c('validation-provider',{attrs:{"name":"Organization name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
							var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"inputName","state":errors.length > 0 ? false : null,"placeholder":"Organization name"},model:{value:(_vm.data.selectedOrganization.name),callback:function ($$v) {_vm.$set(_vm.data.selectedOrganization, "name", $$v)},expression:"data.selectedOrganization.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Shortname","label-for":"inputShortname","label-cols-md":"12"}},[_c('validation-provider',{attrs:{"name":"Organization shortname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
							var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"inputShortname","state":errors.length > 0 ? false : null,"placeholder":"Organization shortname"},model:{value:(_vm.data.selectedOrganization.shortname),callback:function ($$v) {_vm.$set(_vm.data.selectedOrganization, "shortname", $$v)},expression:"data.selectedOrganization.shortname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"HHIP Result URL","label-for":"inputUrl","label-cols-md":"12"}},[_c('validation-provider',{attrs:{"name":"Organization Result URL","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
							var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"inputUrl","placeholder":"HHIP Result URL","rows":"3"},model:{value:(_vm.data.selectedOrganization.resulturl),callback:function ($$v) {_vm.$set(_vm.data.selectedOrganization, "resulturl", $$v)},expression:"data.selectedOrganization.resulturl"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"HHIP Password","label-for":"inputPassword","label-cols-md":"12"}},[_c('validation-provider',{attrs:{"name":"Organization password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
							var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"inputPassword","placeholder":"HHIP password","rows":"3"},model:{value:(_vm.data.selectedOrganization.password),callback:function ($$v) {_vm.$set(_vm.data.selectedOrganization, "password", $$v)},expression:"data.selectedOrganization.password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"type":"submit","variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_vm._v(" Save ")])],1)],1)],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }